<template>
  <div>
    <section>
      <div class="row">
        <div class="col-md-12">
          <div class="generic-wizard-heading">Select Your Program</div>
        </div>
      </div>
      <div class="generic-layout-cntr" v-if="academicPrograms">
        <div class="board-box" v-for="(program, index) in academicPrograms" :key="index" @click="UpdateNextWizard(program)">
          <div class="board-box-head">
            {{ program.AcademicProgramName }}
          </div>
        </div>
      </div>
      <skeleton-loader id="boardLoader" :count="3"></skeleton-loader>
      <div class="d-flex justify-content-center">
        <button @click="goBack()" class="btn secondary-btn">Go back</button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import breadcrumb from "../breadcrumb.vue";
import { appConstants } from "../../../common/constants";
import { exam as examStoreKeys, course as courseStoreKeys } from "../../../store/keys";
export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      selectedAcademicProgram: null,
    };
  },
  mounted() {
    this.getExamsPrograms();
  },
  computed: {
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
  },
  methods: {
    ...mapActions(examStoreKeys.namespace, examStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    courseSubscriptionType() {
      let subscriptionType = this.$route.name === "FreeTrialExam" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
      return subscriptionType;
    },
    getExamsPrograms() {
      this.get_Exams_Programs({
        data: {
          courseGroupId: this.selectedSOSCategory.id,
          SubscriptionType: this.courseSubscriptionType()
        },
        config: {
          loader: {
            show: true,
            id: "programLoader",
          },
        },
      }).then((res) => {
        this.updateSelectedSOSCategoryAcademicProgram(
          this.selectedSOSCategoryAcademicProgram.id === 0
            ? this.academicPrograms[0]
            : this.academicPrograms.find((program) => {
                return program.AcademicProgramId === this.selectedSOSCategoryAcademicProgram.id;
              })
        );
        this.update_SelectedSOSCategoryAcademicProgram({
          data: {
            id: 0,
            name: "",
          },
        });
      });
    },
    updateSelectedSOSCategoryAcademicProgram(program) {
      this.selectedAcademicProgram = program;
    },
    UpdateNextWizard(program) {
      this.update_SelectedSOSCategoryAcademicProgram({
        data: {
          id: program.AcademicProgramId,
          name: program.AcademicProgramName,
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.$emit("onUpdateWizard", "wizard-step3");
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    goBack() {
      let name = this.$route.name === 'FreeTrialExam' || this.$route.name === 'FreeTrialClass' ? 'free-trial' : 'category'
      this.$router.push({ name: name }).catch(() => {});
      // this.$router.push({ name: "category" });
    },
    redirectToSelectedProgramCourses(category) {
      this.RouteTo(`/exam/${this.$route.params.academicProgram.toLowerCase()}`);
    },
  },
};
</script>

<style></style>
